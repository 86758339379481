import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "subbody modulewrap"
};
const _hoisted_2 = {
  key: 0,
  class: "uwelist"
};
const _hoisted_3 = {
  class: "coursetitle"
};
const _hoisted_4 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'uwe',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitleByAlias
    } = FFmixin();
    const route = useRoute();
    const FFdata = reactive({
      infodata: [],
      infoDetail: [],
      root: '/course'
    });
    const getData = async () => {
      let alias = route.params.sub;
      setTitleByAlias(6, alias, navSub => {
        proxy.$common.fetch({
          'act': 'telfort_navthird2',
          'exts': 'id=' + navSub.id,
          msg: ''
        }, res => {
          FFdata.infodata = res.infodata;
        });
      });
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      const _directive_viewer = _resolveDirective("viewer");
      return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$common.basic.isArrayExist(_unref(FFdata).infodata) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infodata, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "uweinfo realwrap",
          key: item.id
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$common.langInfo(item.title, item.title_en)), 1)]), _createElementVNode("div", {
          class: "uwecon editor",
          innerHTML: _ctx.$common.langInfo(item.content, item.content_en)
        }, null, 8, _hoisted_4)]);
      }), 128))])) : _createCommentVNode("", true)])), [[_directive_viewer]]);
    };
  }
};